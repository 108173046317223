import { apiInstance } from "./ApiInstance";

class InscripcionService {
    static async lista(filtros){
        try {
            let query = '';
            filtros.programa ? query += `?programa=${filtros.programa}` : query += '?programa=';

            const response = await apiInstance.get(`/inscripcion/mis-inscripciones${query}`);
            return response;            
        } catch (error) {
            return error;
        }
    }

    static async getInscripcion(id){
        try {
            const response = await apiInstance.get(`/inscripcion/${id}`);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    static async registrar(data){
        try {
            const response = await apiInstance.post(`/inscripcion`, data);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    static async actualizarPrimerPaso(data, id){
        try {
            const response = await apiInstance.patch(`/inscripcion/primer-paso/${id}`, data);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    static registrarModalidadFacturacion(data, id){
        return apiInstance.patch(`/inscripcion/segundo-paso/${id}`, data);
    }

    static subirContrato(data, id){
        return apiInstance.patch(`/inscripcion/subir-contrato/${id}`, data);
    }

    static async actualizarRespaldo(data, inscripcionId){
        try {
            const response = await apiInstance.patch(`/inscripcion/subir-archivo-respaldo/${inscripcionId}`, data);
            return response;
        } catch (error) {
            return error;
        }
    }

    static async paises(){
        try {
            const response = await apiInstance.get(`/pais`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async departamentos(){
        try {
            const response = await apiInstance.get(`/departamento`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async municipios(departamentoId){
        try {
            const response = await apiInstance.get(`/municipio/${departamentoId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async estadosCivil(){
        try {
            const response = await apiInstance.get(`/estado-civil`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async universidades(){
        try {
            const response = await apiInstance.get(`/universidad`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async areasEstudio(){
        try {
            const response = await apiInstance.get(`/area`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async gradosAcademicos(){
        try {
            const response = await apiInstance.get(`/grado-academico`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async buscarPersona(documento){
        try {
            const response = await apiInstance.get(`/persona/documento/${documento}`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            // console.error(error.);
            return error;
        }
    }

    static async getInscripcionEstudiante(estudianteId){
        try {
            const response = await apiInstance.get(`/inscripcion/estudiante/${estudianteId}`);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    static async tiposDocumento(){
        try {
            const response = await apiInstance.get(`/tipos-documento`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async getPlanPagos(data){
        try {
            const response = await apiInstance.post(`/plan-pago`, data);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    static async getProgramasPendientesPago(busqueda){
        
        try {
            const response = await apiInstance.get(`/plan-pago/buscar-pendientes`, {params:{busqueda:busqueda}});
            return response;
        } catch (error) {
            return error;
        }
    }
}

export { InscripcionService }